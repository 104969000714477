@tailwind base;
@tailwind components;


/*** */
.nav_l1_container{
    @apply w-full h-auto flex flex-row justify-between border-b border-gray-200 bg-gray-100;
}

/****  XS max = 414 */

@tailwind utilities;