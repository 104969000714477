@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.product-card{
    width: 250px;
    height: 410px;
    margin: 4px auto;
}


.bg-red{
    background-color: #DC3535;
    
}
.small-product-card{
    width: 20%;
}
.product-card-image{
    width: 250px;
    height: 250px;
   
}
#promobanner{
    background-image: url(images/banner/destock.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.ping{
    color:#000;
}
#promotion{
    background-image: url(images/banner/promo.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.card-content:before{
    position: absolute;
    content: '';
    width: 250px;
    height: 250px;
    top: -60px;
    left: 0;
    transform: skewY(-20deg);
    z-index: -1;
    
}

.product-card:hover .card-content:before{
    background-color: #fef3c7;
    transition: all 500ms ease-in-out;
}
.product-card:hover .product-card-image{
    transform: scale(80%);
    transition: all 500ms ease-in-out;
}
.product-card:hover .card-button{
    background-color: #000;
    color: #fff;
    transition: all 500ms ease-in-out;
}
.big-banner{
    height: 400px;
}

.product-detail-image{
    width: 470px;
    height: 420px;
}
.openBrandList{
    height: auto;
    transition: all 3000ms ease-in-out;
}
.closeBrandList{
    
    transition: all 2500ms ease-in-out;
}
#samecat .slick-slide{
    width: 240px !important;
}
/*
.addtocartanimation{
    animation: pulse 1.5s ease-in 0s,
               move 1s ease 0s;
}
*/
@keyframes pulse {
    0% {
      width: 100%;
      height: 100%;
    }
    
    10% {
        width: 5%;
        height: 5%;
        
    }
  }

  @keyframes move {
    0% {
      transform: translate(1px, 1px);
    }
    
    15% {
        transform: translate(-50px, -50px);
    }

    100% {
        transform: translate(50px, 50px);
    }
  }
/*
.slick-slide{
    width: auto !important;
}*/

.show{
    display: flex;
}
.notShow{
    padding-left: 20px;
    padding-right: 20px;
    border:2px solid greenyellow

}
#content{
    max-width: 1200px;
    width:1200px;
    height: auto;
}

.openmenu{
    display: block;
}
@media (max-width: 720px) {
    .product-card{
        width: 142px;
        height: 295px;
    }
    .product-card-image{
        width: 135px;
        height: 135px;
        margin-left: auto;
        margin-right: auto;
    }
    .product-detail-image{
        width: 100%;
        height: 100vh;
    }
}

.labelsize{
    width: 163px;
    height: 124px;
    font-size: 11px;
    line-height: normal;
    background-color: #fff;
    color: #000;

}

.tbx{
    width: 160px;
    height: 121px; 
}